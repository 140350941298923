import { useEffect } from 'react'
import redirectToHomepage from '../logic/redirectToHomepage'
import { useRouter } from 'next/router'

const Home = () => {
  const router = useRouter()
  useEffect(() => {
    redirectToHomepage(router)
  }, [router])

  return null
}

export default Home
