import { defaultStudentPage, navigationPush } from '../navigation/studentPages'
import { getUser } from '../requests/studentRequests'
import { defaultTeacherPage } from '../navigation/teacherPages'
import { defaultAdminPage } from '../navigation/adminPages'
import notLoggedInPages from '../navigation/notLoggedInPages'

const redirectToHomepage = async (router) => {
  if (!localStorage.getItem('access_token')) {
    navigationPush(notLoggedInPages.signIn.getRoute(), router, notLoggedInPages.signIn.v1)
    return
  }

  try {
    const user = await getUser()
    if (['student', 'personal'].includes(user.role)) {
      navigationPush(defaultStudentPage.route, router, defaultStudentPage.v1)
    } else if (user.role === 'teacher' || user.role === 'school_admin') {
      navigationPush(defaultTeacherPage.getRoute(user.schoolId, user.id), router, defaultTeacherPage.v1)
    } else if (user.role === 'admin') {
      navigationPush(defaultAdminPage.route, router, defaultAdminPage.v1)
    }
  } catch (e) {
    localStorage.removeItem('access_token')
    navigationPush(notLoggedInPages.signIn.getRoute(), router, notLoggedInPages.signIn.v1)
  }
}

export default redirectToHomepage
