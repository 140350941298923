const adminPages = {
  dashboard: {
    route: '/admin/dashboard',
    v1: false,
  },
  upcomingClasses: {
    route: '/admin/classes/upcoming',
    v1: true,
  },
  activeClasses: {
    route: '/admin/classes/active',
    v1: true,
  },
  search: {
    route: '/admin/search',
    v1: true,
  },
  schools: {
    route: '/admin/schools',
    v1: false,
  },
  admins: {
    route: '/admin/admins',
    v1: false,
  },
  manageContentful: {
    route: '/admin/manage-contentful',
    v1: false,
  },
  school: {
    getRoute: (schoolId) => `/admin/schools/${schoolId}`,
    v1: false,
  },
  students: {
    route: '/admin/students',
    v1: false,
  },
  personalUsers: {
    route: '/admin/personal-users',
    v1: false,
  },
  teachers: {
    route: '/admin/teachers',
    v1: false,
  },
  addNewSchool: {
    route: '/admin/schools/add',
    v1: false,
  },
  pendingTeachers: {
    route: '/admin/teachers/pending',
    v1: false,
  },
  restrictedTeachers: {
    route: '/admin/teachers/restricted',
    v1: false,
  },
  profile: {
    route: '/admin/profile',
    v1: true,
  },
}

export const defaultAdminPage = adminPages.dashboard

export default adminPages
