const teacherPages = {
  addNewClass: {
    getRoute: (schoolId, teacherId) => `/schools/${schoolId}/teachers/${teacherId}/classrooms/add`,
    v1: false
  },
  myClasses: {
    getRoute: (schoolId, teacherId) => `/schools/${schoolId}/teachers/${teacherId}/classrooms`,
    v1: false,
  },
  classroom: {
    getRoute: (schoolId, teacherId, classroomId) => `/schools/${schoolId}/teachers/${teacherId}/classrooms/${classroomId}`,
    v1: false
  },
  classroomEdit: {
    getRoute: (schoolId, teacherId, classroomId) => `/schools/${schoolId}/teachers/${teacherId}/classrooms/${classroomId}/edit`,
    v1: false
  },
  courseContent: {
    getRoute: (schoolId, teacherId) => `/schools/${schoolId}/teachers/${teacherId}/course-content`,
    v1: false,
  },
  lessonSummary: {
    getRoute: (schoolId, teacherId, lessonSummaryId) => `/schools/${schoolId}/teachers/${teacherId}/course-content/lessons/${lessonSummaryId}`,
    v1: false
  },
  videoHighlight: {
    getRoute: (schoolId, teacherId, lessonSummaryId, videoHighlightId) => `/schools/${schoolId}/teachers/${teacherId}/course-content/lessons/${lessonSummaryId}/${videoHighlightId}`,
    v1: false
  },
  dailBeatPlayer: {
    getRoute: (schoolId, teacherId, id) => `/schools/${schoolId}/teachers/${teacherId}/course-content/dailyBeats/${id}`,
    v1: false
  },
  profile: {
    getRoute: (schoolId, teacherId) => `/schools/${schoolId}/teachers/${teacherId}/profile`,
    v1: false,
  },
  accountSettings: {
    getRoute: (schoolId, teacherId) => `/schools/${schoolId}/teachers/${teacherId}/profile/account-settings`,
    v1: false,
  },
  updateName: {
    getRoute: (schoolId, teacherId) => `/schools/${schoolId}/teachers/${teacherId}/profile/update-name`,
    v1: false
  },
  updatePassword: {
    getRoute: (schoolId, teacherId) => `/schools/${schoolId}/teachers/${teacherId}/profile/update-password`,
    v1: false
  },
  deleteAccount: {
    getRoute: (schoolId, teacherId) => `/schools/${schoolId}/teachers/${teacherId}/profile/delete-account`,
    v1: false
  },
  deleteAccountConfirm: {
    getRoute: (schoolId, teacherId) => `/schools/${schoolId}/teachers/${teacherId}/profile/delete-account-confirm`,
    v1: false
  },
  studentDrillDown: {
    getRoute: (schoolId, teacherId, classroomId, studentId) => `/schools/${schoolId}/teachers/${teacherId}/classrooms/${classroomId}/students/${studentId}`,
    v1: false
  },
  classroomModeLessonPlayer: {
    getRoute: (schoolId, teacherId, classroomId, lessonId) => `/schools/${schoolId}/teachers/${teacherId}/classrooms/${classroomId}/lesson/${lessonId}`,
    v1: false
  },
  classroomModeDailyBeatPlayer: {
    getRoute: (schoolId, teacherId, classroomId, dailyBeatId) => `/schools/${schoolId}/teachers/${teacherId}/classrooms/${classroomId}/dailyBeat/${dailyBeatId}`,
    v1: false
  },
  gradeView: {
    getRoute: (schoolId, teacherId, classroomId) => `/schools/${schoolId}/teachers/${teacherId}/classrooms/${classroomId}/grades`,
    v1: false
  },
  teacherResources: {
    getRoute: (schoolId, teacherId) => `/schools/${schoolId}/teachers/${teacherId}/resources`,
    v1: false
  },
  resourceVideo: {
    getRoute: (schoolId, teacherId, resourceVideoId) => `/schools/${schoolId}/teachers/${teacherId}/resources/${resourceVideoId}`,
    v1: false
  },
  resetStudentViewConfirm: {
    getRoute: (schoolId, teacherId) => `/schools/${schoolId}/teachers/${teacherId}/profile/reset-student-view-confirm`,
    v1: false
  }
}

export const defaultTeacherPage = teacherPages.teacherResources

export default teacherPages
